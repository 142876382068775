import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const onRedirectCallback = (appState) => {
  window.history.replaceState(
    {},
    document.title,
    appState.targetUrl || window.location.pathname
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);
