import React from 'react';
import {
  BrowserRouter,
  Route,
  Routes
} from "react-router-dom";
import { Helmet } from 'react-helmet';

import { UserProfileProvider } from "./context/UserProfileContext"; // Import your context

import LandingPage from './pages/Main/LandingPage';
import AboutJLPT from './pages/Main/AboutJLPT';
import Textbooks from './pages/Main/Resources/Textbooks/Textbooks';
import LevelTest from './pages/Main/LevelTest';

import JLPTNavBar from './pages/JLPT/PracticeLayout'; // Updated import for NavBar
import Dashboard from './pages/JLPT/Dashboard/Dashboard';
import Subject from './pages/JLPT/Practice/Subject';
import SubjectQuiz from './pages/JLPT/Practice/SubjectQuiz';
import Resources from './pages/JLPT/Resources/Resources';
import NotFound from './pages/NotFound'; // 404 page

import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

function App() {
  return (
    <div>
      <Helmet>
        <title>Ace JLPT - Practice & Excel in Japanese Learning & JLPT</title>
        <meta name="description" content="Prepare for the Japanese Language Proficiency Test (JLPT) with AceJLPT.com. Access 10,000+ real exam questions, textbooks, and comprehensive study materials." />
        <meta name="keywords" content="JLPT, Japanese proficiency, question bank, JLPT preparation, Japanese study materials, online JLPT practice" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Ace JLPT - Practice & Excel in Japanese Learning & JLPT" />
        <meta property="og:description" content="AceJLPT.com offers thousands of authentic practice questions, textbooks, and learning resources to help you succeed in your Japanese language study and JLPT." />
        <meta property="og:url" content="https://www.acejlpt.com" />
        <meta property="og:type" content="website" />
      </Helmet>

      <UserProfileProvider>
        <BrowserRouter>
          <Routes>
            {/* Main pages */}
            <Route index element={<LandingPage />} />
            <Route path="about_jlpt" element={<AboutJLPT />} />
            <Route path="resources/textbooks" element={<Textbooks />} />

            <Route path="level_test" element={<LevelTest />} />

            {/* JLPT Pages with NavBar */}
            <Route path=":level" element={<JLPTNavBar />}>
              <Route index element={<Dashboard />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path=":subject" element={<Subject />} />
              <Route path="practice/:subject/:yearMonth" element={<SubjectQuiz />} />
            </Route>

            {/* 404 page */}
            <Route path="*" element={<NotFound />} /> 
          </Routes>
        </BrowserRouter>
      </UserProfileProvider>
    </div>
  );
}

export default App;
