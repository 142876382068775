import React from 'react';
import LandingPageNaviBar from '../../../Main/LandingPageNaviBar';
import { Helmet } from 'react-helmet';

const Textbooks = () => {
  return (
    <div>
      <Helmet>
        <title>Ace JLPT - Download Minna no Nihongo</title>
        <meta name="description" content="Explore Minna no Nihongo, a popular and widely used textbook series for learning Japanese. Perfect for beginners and intermediate learners looking to build a solid foundation in the language." />
        <meta name="keywords" content="Minna no Nihongo, Japanese textbooks, learn Japanese, JLPT practice, Japanese language study, language learning resources" />
        <meta name="author" content="Ace JLPT" />
        <meta property="og:title" content="Ace JLPT - Download Minna no Nihongo" />
        <meta property="og:description" content="Learn Japanese effectively with the Minna no Nihongo textbook series. Widely used by language schools, universities, and self-learners." />
      </Helmet>

      <LandingPageNaviBar />
      <div className="container mt-5">
        <br></br>
        <h3>Minna no Nihongo (みんなの日本語)</h3>
        <img src={require('../../../../assets/minna_no_nihongo_cover.webp')} className="mb-3 img-fluid" alt="JLPT Logo" />
        <p>Minna no Nihongo (みんなの日本語) is a popular and widely used textbook series for learning Japanese. It is designed for beginners and intermediate learners and is especially favored by those who want to build a strong foundation in the language.</p>
        <p>It is widely used in language schools, universities, and self-study programs across the world. Its structured approach is ideal for students who want a solid grounding in Japanese with a focus on practical communication skills.</p>
      </div>
      <div className="card text-center container">
        <div className="card-body">
            <h5 className="card-title">Download Minna no Nihongo I</h5>
            <p className="card-text">Click the button below to download the PDF.</p>
            <a href={require('../../../../textbooks/Minna_No_Nihongo_I.pdf')} download="Minna_No_Nihongo_I.pdf" className="btn btn-primary">
                Download PDF
            </a>
        </div>
      </div>
      <br></br>
      <div className="card text-center container">
        <div className="card-body">
            <h5 className="card-title">Download Minna no Nihongo II</h5>
            <p className="card-text">Click the button below to download the PDF.</p>
            <a href={require('../../../../textbooks/Minna_No_Nihongo_II.pdf')} download="Minna_No_Nihongo_II.pdf" className="btn btn-primary">
                Download PDF
            </a>
        </div>
      </div>

    </div>
  );
}

export default Textbooks;
