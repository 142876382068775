import React from 'react';
import { useParams, Outlet, Link } from 'react-router-dom';
import NotFound from '../NotFound';

const JLPTNavBar = () => {
  const { level } = useParams();

  const allowedLevels = ['n1', 'n2', 'n3', 'n4', 'n5'];
  if (!allowedLevels.includes(level)) {
    // Redirect to a not-found page or another appropriate route
    return <NotFound />;
}

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <a className="navbar-brand" href="#">Ace JLPT {level}</a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav">

            <li className="nav-item active">
              <Link className="nav-link" to={`/${level}/vocabulary`}>Vocabulary</Link>
            </li>

            <li className="nav-item active">
              <Link className="nav-link" to={`/${level}/grammar`}>Grammar</Link>
            </li>

            <li className="nav-item active">
              <Link className="nav-link" to={`/${level}/reading`}>Reading</Link>
            </li>

            <li className="nav-item active">
              <Link className="nav-link" to={`/${level}/listening`}>Listening</Link>
            </li>

          </ul>
        </div>

        {/* <form className="form-inline">
          <button className="btn btn-outline-success" type="button">Login</button>
        </form> */}
        
      </nav>

      <Outlet />
    </div>
  );
};

export default JLPTNavBar;


